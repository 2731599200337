#root,
body,
html {
    // height: 100%;
    min-height: 540px;
    font-family: "Open Sans", "Helvetica", "Arial", sans-serif;
    margin: 0px;
    box-sizing: border-box;
    color: #4a4a4d;
}

.appDiv {
    height: 100vh;
    width: 100vw;
}

@import "./definitions.scss";

.dashboard {
    &-drawer {
        .MuiPaper-root {
            position: absolute;
            top: inherit;
            left: inherit;
            right: inherit;
        }
        .MuiDrawer-paperAnchorLeft {
            box-shadow: $LayoutShadow;
        }
        .MuiDrawer-paperAnchorRight {
            box-shadow: -$LayoutShadow;
        }
    }
}

.navigation-drawer {
    .MuiPaper-root {
        top: inherit;
        left: inherit;
        right: inherit;
        z-index: 10000;
        flex-direction: row;
    }
}

.appBarDiv {
    padding: 4px;
    background-color: #e7e7e7 !important;
    width: auto !important;
    &_siteAdvisor {
        height: 50px;
        min-height: 50px;
        background-image: url("./Images/ColliersHeaderBackground.png");
        .appbar_logo {
            background-image: url("./Images/site-advisor-logo.png");
            width: 237px;
            height: 41px;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }

    &_dashboard {
        box-shadow: 0px 4px 13px -3px #64646463 !important;
        position: relative !important;
        z-index: 1000;
        border-bottom: 1px solid inherit !important;
        padding: 0;
        .MuiToolbar-root {
            float: left;
            min-height: 40px;
            &:first-child {
                width: 240px;
                background-color: $Colliers-Blue--hover;
            }
            &:last-child {
                width: calc(100% - 240px);
                display: flex;
                color: $Colliers-Blue;
                justify-content: space-between;
                div > div {
                    text-align: center;
                    height: 18px;
                    &:last-child {
                        font-weight: bold;
                    }
                }
            }
        }
    }
}

.navigation {
    width: 60px;
    height: calc(100vh - 50px);
    float: left;
    background-color: #e7e7e7;
    &_button {
        &.Mui-selected {
            background-color: $Colliers-Blue--hover !important;
            svg {
                fill: $Colliers-White !important;
            }
        }
    }
}

.MuiTooltip-popper {
    z-index: 10000 !important;
}

@import "./definitions.scss";

.splash {
    height: 100%;
    width: 100%;
    position: relative;
    color: $Colliers-Blue;
    &_content {
        height: 10%;
        width: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        animation: fadein 2s;
        svg {
            height: 100px;
            width: 100%;
        }
        &-message {
            width: 100%;
        }
    }
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.yellow-animate {
    animation: 3s yellow infinite;
}

.blue-animate {
    animation: 3s blue infinite;
    animation-delay: 500ms; /* or: Xms */
}

.red-animate {
    animation: 3s red infinite;
    animation-delay: 1s; /* or: Xms */
}

@keyframes yellow {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes blue {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes red {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

/* ------------ import Google fonts ------------ */
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans+Condensed:400,700);
@import url(https://fonts.googleapis.com/css?family=Merriweather:400,700);

$Colliers-Blue: #25408f;
$Colliers-Blue--hover: #0c9ed9;
$Colliers-White: #ffffff;
$Colliers-Grey: #777777;
$Colliers-Error: #ed1b34;

$PdfColor: #b30b00;
$ExcelColor: #0c7238;

// Screen
$small: 500px;

//Map
$Highlight: #36f5ff;

//Layout

$LayoutShadow: 3px 0px 7px 0px #bbbbbb63;

//ESRI
$Esri-border: solid 1px rgba(110, 110, 110, 0.3);
$Esri-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);

// Font Variables
$MainFont: "Open Sans", sans-serif;
$SubFont: "Merriweather", sans-serif;
